import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const FilterServices = ({ services, onFilter }) => {
  const [filters, setFilters] = useState({
    status: "",
    customer: "",
    address: "",
    assignedTo: "",
    createdBy: "",
  });

  const uniqueValues = (key) => {
    if (key === "createdBy") {
      return [...new Map(
        services.map(service => [service[key]._id, `${service[key].firstName} ${service[key].lastName}`])
      ).values()];
    }
    return [...new Set(services.map((service) => service[key]))];
  };
  

  const handleChange = (e) => {


    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleFilter = () => {
    onFilter(filters);
  };

  return (
    <Box display="flex" gap={2} mb={2} sx={{marginTop: 2}}>
      <TextField
        select
        label="Status"
        name="status"
        value={filters.status}
        onChange={handleChange}
        variant="outlined"
        size="small"
        sx={{ minWidth: 120, marginLeft: 2 }}
      >
        <MenuItem value="">All</MenuItem>
        {uniqueValues("status").map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </TextField>
      
      <TextField
        label="Customer Name"
        name="customer"
        value={filters.customer}
        onChange={handleChange}
        variant="outlined"
        size="small"
      />
      
      <TextField
        label="Address"
        name="address"
        value={filters.address}
        onChange={handleChange}
        variant="outlined"
        size="small"
      />
      
      <TextField
        select
        label="Assigned To"
        name="assignedTo"
        value={filters.assignedTo}
        onChange={handleChange}
        variant="outlined"
        size="small"
        sx={{ minWidth: 120 }}
      >
        <MenuItem value="">All</MenuItem>
        {uniqueValues("assignedTo").map((assignee) => (
          <MenuItem key={assignee} value={assignee}>
            {assignee}
          </MenuItem>
        ))}
      </TextField>
      
     <TextField
  select
  label="Created By"
  name="createdBy"
  value={filters.createdBy}
  onChange={handleChange}
  variant="outlined"
  size="small"
  sx={{ minWidth: 120 }}
>
  <MenuItem value="">All</MenuItem>
  {services
    .filter((service, index, self) =>
      index === self.findIndex((s) => s.createdBy._id === service.createdBy._id)
    ) // Ensure unique entries
    .map((service) => (
      <MenuItem key={service.createdBy._id} value={`${service.createdBy.firstName} ${service.createdBy.lastName}`}>
        {service.createdBy.firstName} {service.createdBy.lastName}
      </MenuItem>
    ))}
</TextField>

      <Button variant="contained" onClick={handleFilter}>
        Apply Filters
      </Button>
    </Box>
  );
};

export default FilterServices;