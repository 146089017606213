import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import ModalService from "./ModalService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Textarea from "@mui/joy/Textarea";
import { TableSortLabel } from "@mui/material";
import Chip from "@mui/material/Chip";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateServiceModal from "./UpdateServiceModal";
import FilterServices from "./FilterServices";




const ServicesTable = (props) => {
  console.log(props)
  const servicesList = props.services;

  const [orderBy, setOrderBy] = useState("dos");
  const [order, setOrder] = useState("asc");

  //variables
  const [userId, setUserId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [custFirstName, setCustFirstName] = useState("");
  const [custLastName, setCustLastName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [servId, setServId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteConfirmText, setDeleteConfirmText] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);



  //modal variables
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [description, setDescription] = useState("");

  //update modal states
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  //Logica para filtros TESTING
  const [filteredServices, setFilteredServices] = useState(props.services);

const handleFilter = (filters) => {
  const filtered = props.services.filter((service) => {
    return (
      (filters.status === "" || service.status === filters.status) &&
      (filters.customer === "" || 
        (`${service.firstName} ${service.lastName}`)
          .toLowerCase()
          .includes(filters.customer.toLowerCase())) &&
      (filters.address === "" || service.address.toLowerCase().includes(filters.address.toLowerCase())) &&
      (filters.assignedTo === "" || service.assignedTo === filters.assignedTo) &&
      (filters.createdBy === "" || 
        (`${service.createdBy.firstName} ${service.createdBy.lastName}`) 
          .toLowerCase()
          .includes(filters.createdBy.toLowerCase()))
    );
  });
  setFilteredServices(filtered);
};

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/getUser", {
        withCredentials: true,
      })
      .then((response) => {
        setUserId(response.data._id);
        setUserFirstName(response.data.firstName);
        setUserLastName(response.data.lastName);
        setUserRole(response.data.role);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOpenServiceModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleNewServiceSubmit = (newService) => {
    // Handle submission of new service form data
    console.log("New service data:", newService);

    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/service/new`,
        { newService },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);

        props.onNewService();
      })
      .catch((err) => console.log(err));

    // Don't forget to close the modal after successful submission
    setIsModalOpen(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getStatusChip = (status) => {
    let color = "default";
    if (status === "In Progress") color = "info";
    else if (status === "Completed") color = "success";
    else if (status === "Service Required") color = "warning";
    
    return <Chip label={status} color={color} />;
  };

  //Logic for deleting a service and modal
  //Opening Modal
  const handleOpenDeleteModal = (servId) => {
    console.log("Delete service with ID:", servId);
    setDeleteId(servId);
    setOpenDeleteModal(true);
    setDeleteConfirmText(""); //Reset the text field
  }

  //Closing Modal
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeleteId(""); //Reset the service ID
  }

  //Handle delete confirmation
  const handleDeleteConfirmation = () => {
    //Check if the text matches "delete"
    if (deleteConfirmText.toLowerCase() === "delete") {
      //Delete the service
      axios
        .delete(process.env.REACT_APP_API_URL + `/api/service/${deleteId}`, {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response.data);
          props.onNewService(); //Refresh the service list
          setOpenDeleteModal(false); //Close the modal
        })
        .catch((err) => console.log(err));
    }
  }

  //Logic for Updating a service and modal
  const handleOpenUpdateModal = (service) => {
    setSelectedService(service);
    setOpenUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
    setSelectedService(null);
  };

 

  if (userRole === "admin" && props.services) {
    return (
      <div>
        <h1>Services</h1>

        <Button
   
          size="small"
          onClick={handleOpenServiceModal}
          variant="outlined"
          sx={{ marginLeft: 2 }}
        >
          Request a New Service
        </Button>

        <ModalService
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleNewServiceSubmit}
        />
        <FilterServices services={props.services} onFilter={handleFilter} />

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 840 }}>
            <Table
              sx={{ minWidth: 650 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      sx={{
                        padding: 3,
                        borderBottom: "1px solid gray",
                        minHeight: 81,
                      }}
                      active={orderBy === "dos"}
                      direction={order}
                      onClick={() => handleRequestSort("dos")}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell>Created by</TableCell>
                  <TableCell>Updated at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredServices.map((eachServ, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {moment(eachServ.createdAt).format("MMM DD, YY")}
                    </TableCell>
                    <TableCell>{getStatusChip(eachServ.status)}</TableCell>
                    <TableCell>
                   
                        {eachServ.firstName} {eachServ.lastName}
  
                    </TableCell>
                    <TableCell>{eachServ.phone}</TableCell>
                    <TableCell>{eachServ.address}</TableCell>
                    <TableCell>{eachServ.assignedTo}</TableCell>
                    <TableCell>{eachServ.description}</TableCell>
                    <TableCell>
                      <Button
                        sx={{ marginBottom: 1 }}
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => handleOpenDeleteModal(eachServ._id)}
                      >
                        delete
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="info"
                        sx={{marginBottom: '10px'}} 
                       //onClick={() => handleOpenDeleteModal(eachServ._id, "comment")}
                      >
                        Comments
                      </Button>
                      <Button size="small" variant="contained" color="info" onClick={() => handleOpenUpdateModal(eachServ)}>
                  Edit / Update
                </Button>
                    </TableCell>
                    <TableCell>
                      {eachServ.comments.length !== 0
                        ? eachServ.comments[eachServ.comments.length - 1].text
                        : "No comments"}
                    </TableCell>
                    <TableCell>
                      {eachServ.createdBy.firstName}{" "}
                      {eachServ.createdBy.lastName}
                    </TableCell>
                  
                    <TableCell>
                      {moment(eachServ.updatedAt).format("dddd LT MM/DD/YY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
          </TableContainer>
        </Paper>
        {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirmation}
        confirmText={deleteConfirmText}
        setConfirmText={setDeleteConfirmText}
      />
        {/* Update Service Modal */}
        <UpdateServiceModal
        open={openUpdateModal}
        onClose={handleCloseUpdateModal}
        serviceData={selectedService}
        onUpdate={props.onNewService} // Refresh service list
      />
    
      </div>
    );
  } else {
    return "YOU ARE NOT AN ADMIN"; // or any other component or message you want to display for non-admin users
  }
};

export default ServicesTable;
