import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@mui/material";

const DeleteConfirmationModal = ({ open, onClose, onConfirm, confirmText, setConfirmText }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please type <strong>"delete"</strong> to confirm deletion:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Type 'delete' to confirm"
          fullWidth
          variant="outlined"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          disabled={confirmText.toLowerCase() !== "delete"}
        >
          Confirm Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
