import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete as MUIAutocomplete,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const ModalService = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",  // ✅ Address is now just a regular input
    phone: "",
    status: "",
    waterSource: "",
    description: "", // ✅ Kept the description field
    createdBy: "",
    assignedTo: "",
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // ✅ Fetch Users List When Modal Opens
  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/allUsers`, {
          withCredentials: true,
        })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching users:", err);
          setLoading(false);
        });
    }
  }, [isOpen]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" }); // ✅ Clear error on input change
  };

  // ✅ Validate Required Fields Before Submission
  const validateFields = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = "First Name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last Name is required";
    if (!formData.address.trim()) newErrors.address = "Address is required";
    if (!formData.phone.trim()) newErrors.phone = "Phone is required";
    if (!formData.status) newErrors.status = "Status is required";
    if (!formData.waterSource) newErrors.waterSource = "Water Source is required";
    if (!formData.description.trim()) newErrors.description = "Description is required"; 
    if (!formData.createdBy) newErrors.createdBy = "Created By is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // ✅ Handle Form Submission
  const handleSubmit = () => {
    if (!validateFields()) return; // ✅ Prevent submission if validation fails

    onSubmit(formData); // ✅ Send form data to parent component

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      phone: "",
      status: "",
      waterSource: "",
      description: "",
      createdBy: "",
      assignedTo: "",
    });

    onClose(); // ✅ Close modal after submission
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Service</DialogTitle>
      <DialogContent>
        <TextField
          name="firstName"
          label="First Name"
          fullWidth
          margin="normal"
          value={formData.firstName}
          onChange={handleChange}
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
        <TextField
          name="lastName"
          label="Last Name"
          fullWidth
          margin="normal"
          value={formData.lastName}
          onChange={handleChange}
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
        <TextField name="email" label="Email" fullWidth margin="normal" value={formData.email} onChange={handleChange} />
        <TextField
          name="phone"
          label="Phone"
          fullWidth
          margin="normal"
          value={formData.phone}
          onChange={handleChange}
          error={!!errors.phone}
          helperText={errors.phone}
        />

        {/* ✅ Address is now just a regular text field */}
        <TextField
          name="address"
          label="Address"
          fullWidth
          margin="normal"
          value={formData.address}
          onChange={handleChange}
          placeholder="Enter address manually"
          error={!!errors.address}
          helperText={errors.address}
        />

        {/* Created By (Dropdown Selection) */}
        <MUIAutocomplete
          options={users}
          getOptionLabel={(user) => `${user.firstName} ${user.lastName} (${user.role})`}
          isOptionEqualToValue={(option, value) => option._id === value?._id}
          value={users.find((user) => user._id === formData.createdBy) || null}
          loading={loading}
          onChange={(event, newValue) => {
            setFormData({ ...formData, createdBy: newValue ? newValue._id : "" });
            setErrors({ ...errors, createdBy: "" });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Created By"
              fullWidth
              margin="normal"
              error={!!errors.createdBy}
              helperText={errors.createdBy}
            />
          )}
        />

        <TextField name="assignedTo" label="Assigned To" fullWidth margin="normal" value={formData.assignedTo} onChange={handleChange} />

        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select name="status" value={formData.status} onChange={handleChange}>
            <MenuItem value="Service Required">Service Required</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Water Source</InputLabel>
          <Select name="waterSource" value={formData.waterSource} onChange={handleChange}>
            <MenuItem value="Well water">Well Water</MenuItem>
            <MenuItem value="City water">City Water</MenuItem>
          </Select>
        </FormControl>

        {/* ✅ Description Field is Back */}
        <TextField
          name="description"
          label="Description"
          fullWidth
          multiline
          rows={3}
          margin="normal"
          value={formData.description}
          onChange={handleChange}
          error={!!errors.description}
          helperText={errors.description}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalService;
