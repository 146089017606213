import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const UpdateServiceModal = ({ open, onClose, serviceData, onUpdate }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    phone: "",
    assignedTo: "",
    waterSource: "",
    status: "",
    description: "",
    createdBy: "", // New field for user assignment
  });

  const [users, setUsers] = useState([]); // Store users list
  const [loading, setLoading] = useState(false); // Loading state for API call

  // Fetch users when modal opens
  useEffect(() => {
    if (open) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/allUsers`, {
          withCredentials: true,
        })
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching users:", err);
          setLoading(false);
        });
    }
  }, [open]);

  // Pre-fill form when modal opens
  useEffect(() => {
    if (serviceData) {
      setFormData(serviceData);
    }
  }, [serviceData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdate = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/service/${serviceData._id}`, formData, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Service updated:", response.data);
        onUpdate(); // Refresh list in ServicesTable
        onClose(); // Close modal
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Service</DialogTitle>
      <DialogContent>
        <TextField name="firstName" label="First Name" fullWidth margin="normal" value={formData.firstName} onChange={handleChange} />
        <TextField name="lastName" label="Last Name" fullWidth margin="normal" value={formData.lastName} onChange={handleChange} />
        <TextField name="email" label="Email" fullWidth margin="normal" value={formData.email} onChange={handleChange} />
        <TextField name="address" label="Address" fullWidth margin="normal" value={formData.address} onChange={handleChange} />
        <TextField name="phone" label="Phone" fullWidth margin="normal" value={formData.phone} onChange={handleChange} />
        <TextField name="assignedTo" label="Assigned To" fullWidth margin="normal" value={formData.assignedTo} onChange={handleChange} />

        {/* Created By (Dropdown Selection) */}
        <Autocomplete
          options={users}
          getOptionLabel={(user) => `${user.firstName} ${user.lastName} (${user.role})`}
          isOptionEqualToValue={(option, value) => option._id === value?._id}
          value={users.find((user) => user._id === formData.createdBy) || null}
          loading={loading}
          onChange={(event, newValue) => {
            setFormData({ ...formData, createdBy: newValue ? newValue._id : "" });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Created By"
              fullWidth
              margin="normal"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select name="status" value={formData.status} onChange={handleChange}>
            <MenuItem value="Service Required">Service Required</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Water Source</InputLabel>
          <Select name="waterSource" value={formData.waterSource} onChange={handleChange}>
            <MenuItem value="Well water">Well Water</MenuItem>
            <MenuItem value="City water">City Water</MenuItem>
          </Select>
        </FormControl>

        <TextField name="description" label="Description" fullWidth multiline rows={3} margin="normal" value={formData.description} onChange={handleChange} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update Service
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateServiceModal;
